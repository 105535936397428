import React from "react"
import styled from "styled-components"
import { rem } from "../../util/style"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ImageBlock = styled.div`
  margin-bottom: ${rem(64)};

  display: flex;
  justify-content: stretch;
  align-items: stretch;

  .gatsby-image-wrapper {
    width: 100%;
  }

  img,
  picture {
    width: 100% !important;
    max-width: 100% !important;
  }
`

const BlockMedia = ({ data }) => {
  const isVideo = data.Media.mime?.startsWith("video")

  if (data.Hide) return null

  return (
    <ImageBlock>
      {isVideo ? (
        <p>TODO video</p>
      ) : (
        <GatsbyImage
          image={getImage(data.Media.localFile)}
          alt={data.Media.alternativeText}
        />
      )}
    </ImageBlock>
  )
}

export default BlockMedia
