import React from "react"
import { graphql } from "gatsby"

import Nav from "../components/Navigation"
import Text from "../components/Text"
import BlogSeo from "../components/BlogSeo"
import Blocks from "../components/Blog/BlocksRenderer"
import RecentArticleCard from "../components/Blog/RecentArticleCard"
import Footer from "../components/Footer"
import {
  Main,
  ShowPageBody,
  RecentArticles,
  ArticleList,
  FooterBG,
} from "../style/blog"

import Dots from "../images/dot-pattern.png"

const BlogPost = ({ data }) => {
  const article = data.strapiBlog
  const recents = data.allStrapiBlog.nodes
  console.log(article)
  return (
    <Main>
      <Nav light />
      <img className="dots" src={Dots} />
      <img className="dots" src={Dots} />
      <img className="dots" src={Dots} />
      <ShowPageBody>
        <Blocks blocks={article.Content} />
      </ShowPageBody>
      <RecentArticles>
        <Text size="blogRecent" color="#06122C" mb="16">
          Recent Publications
        </Text>
        <ArticleList>
          {recents.map((item, index) => (
            <RecentArticleCard key={index} article={item} />
          ))}
        </ArticleList>
      </RecentArticles>
      <FooterBG>
        <Footer small />
      </FooterBG>
    </Main>
  )
}

export const Head = ({ data }) => {
  const article = data.strapiBlog
  let siteURL = process.env.SITE_URL || "http://localhost:8000/"
  if (siteURL.endsWith("/")) siteURL = siteURL.substring(0, siteURL.length - 1)

  const title = article.Title || ""
  const description = article.Description || ""
  const picture = `${siteURL}${article.Picture?.localFile?.publicURL}` || ""

  return <BlogSeo title={title} description={description} picture={picture} />
}

export const pageQuery = graphql`
  query ($slug: String) {
    strapiBlog(slug: { eq: $slug }) {
      slug
      ArticleType
      Title
      Description
      PublishDate
      ReadEstimate
      Author {
        Name
      }
      Picture {
        localFile {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      Content {
        ...Content
      }
    }
    allStrapiBlog(limit: 3) {
      nodes {
        ...RecentArticleCard
      }
    }
  }
`

export default BlogPost
