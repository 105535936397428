import React from "react"
import { graphql } from "gatsby"
import BlockRichText from "./BlockRichText"
import BlockMedia from "./BlockMedia"

const componentsMap = {
  STRAPI__COMPONENT_BLOCKS_RICH_TEXT: BlockRichText,
  STRAPI__COMPONENT_BLOCKS_IMAGE: BlockMedia,
}

const Block = ({ block }) => {
  const Component = componentsMap[block.__typename]

  if (!Component) {
    return null
  }

  return <Component data={block} />
}

const BlocksRenderer = ({ blocks }) => {
  return (
    <div>
      {blocks.map((block, index) => (
        <Block key={`${index}${block.__typename}`} block={block} />
      ))}
    </div>
  )
}

export const query = graphql`
  fragment Content on STRAPI__COMPONENT_BLOCKS_IMAGESTRAPI__COMPONENT_BLOCKS_RICH_TEXTUnion {
    __typename
    ... on STRAPI__COMPONENT_BLOCKS_IMAGE {
      id
      Hide
      Media {
        mime
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    ... on STRAPI__COMPONENT_BLOCKS_RICH_TEXT {
      id
      Content {
        data {
          Content
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
`

export default BlocksRenderer
