import React from "react"
import moment from "moment"
import styled from "styled-components"
import { Fonts, Weight } from "../../style/fonts"
import { rem } from "../../util/style"
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Text from "../Text"

const Card = styled(props => <Link {...props} />)`
  margin: 0 ${rem(10)};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: ${rem(413)};

  text-decoration: none;
  margin-bottom: ${rem(42)};
`

const ArticleType = styled.div`
  margin-bottom: ${rem(16)};

  display: inline-block;

  border-radius: 14px;
  padding: 4px 16px;
  background-color: #9ebcf8;
`

const DescriptionBlock = styled.div`
  margin: 0;
  color: #6b7280;
  font-size: ${rem(16)};
  font-family: ${Fonts.inter};
  font-weight: ${Weight.normal};
  line-height: ${rem(24)};

  strong {
    color: #000;
  }
`

const Author = styled.div`
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: ${rem(24)};
`

const AuthorImage = styled.div`
  flex: 0 0 auto;
  margin-right: ${rem(12)};
  width: ${rem(40)};
  height: ${rem(40)};
  border-radius: 50%;
  overflow: hidden;
`

const AuthorContent = styled.div`
  flex: 1 1 auto;
`

const AuthorInitials = styled.div`
  width: ${rem(40)};
  height: ${rem(40)};

  color: white;
  font-size: ${rem(14)};

  display: flex;
  justify-content: center;
  align-items: center;

  background: rgb(6, 18, 44);
`

function formatDate(str) {
  return moment(str).format("MMM D, YYYY")
}

function initials(name) {
  const words = name.split(" ")
  const initials = words.map(word => word[0].toUpperCase()).join("")
  return initials
}

const RecentArticleCard = ({ article }) => {
  return (
    <Card to={`/blog/${article.slug}`}>
      <ArticleType>
        <Text size="tag" color="#0A0969">
          {article.ArticleType}
        </Text>
      </ArticleType>
      <Text size="blogTitle" color="#06122C" mb="12">
        {article.Title}
      </Text>
      <DescriptionBlock>{article.Description}</DescriptionBlock>
      <Author>
        {article.Author && (
          <AuthorImage>
            {article.Author?.Picture ? (
              <GatsbyImage
                image={getImage(article.Author?.Picture?.localFile)}
                alt={article.Author?.Picture?.alternativeText}
              />
            ) : (
              <AuthorInitials>{initials(article.Author?.Name)}</AuthorInitials>
            )}
          </AuthorImage>
        )}

        <AuthorContent>
          {article.Author?.Name && (
            <Text size="author" color="#06122C">
              {article.Author?.Name}
            </Text>
          )}
          <Text size="date" color="#6B7280">
            {formatDate(article.PublishDate)}
            {article.ReadEstimate && <> · {article.ReadEstimate} read</>}
          </Text>
        </AuthorContent>
      </Author>
    </Card>
  )
}

export const query = graphql`
  fragment RecentArticleCard on STRAPI_BLOG {
    id
    slug
    Title
    ArticleType
    Description
    PublishDate
    ReadEstimate
    Author {
      Name
      Picture {
        localFile {
          url
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`

export default RecentArticleCard
